<template>
<div class="widget-min-box">
    <div class="tv-iframe-wrapper">
        <iframe id="tv-iframe" :src="`${this.$helpers.tv4_url()}/?technical_id=${data.id}&${new Date().getTime()}`" style="border:none;width: 100%;display: inline-block;"></iframe>
    </div>
</div>
</template>

<style lang="scss" scoped>
.tv-iframe-wrapper {
    width: 100%;
}

div.right {
    display: none !important;
}
</style>

<script>
// این کامپوننت برای نمایش تحلیل ذخیره شده کاربر استفاده میشود
export default {
    name: 'ViewTechnicalComponent',
    props: ['data'],
    components: {},
    data: function () {
        return {}
    },
    mounted() {
        // در صورتی که منوی داشبورد مخفی بود نمایش داده شود
        var item = document.getElementsByClassName('bar-menu-dashboard-box');
        item[0].style.display = "";
    },
    methods: {},
}
</script>
